<template>
  <Teleport to="body">
    <transition name="slide-fade" mode="out-in">
      <div v-if="localMessages.length" class="p-3 text-white alert-message overflow-auto" style="max-height:90vh;" @click="close">
        <transition-group tag="div" mode="out-in">
          <div v-for="alert in localMessages" :key="alert">
            <span class="bi bi-info-circle lead me-2"></span>
            {{ alert }}
          </div>
        </transition-group>
      </div>

    </transition>
  </Teleport>
</template>

<script setup>

import { defineProps, defineEmits, ref, nextTick, watch, onMounted } from "vue"
const props = defineProps({message: String})

const localMessages = ref([])
const emits = defineEmits(["onHide"])
// const timer = ref(null)

onMounted(() => {
  watch(() => props.message, async (newVal) => {
    if (!newVal) {
      await nextTick()
      return
    }
    await load(newVal)
  });
});

//load(props.message)

async function load(msg) {
  await nextTick()
  localMessages.value.push(msg)
  setTimeout(hide, 5000)
}

function hide() {
  localMessages.value.shift()
  if (localMessages.value.length) return
  close()
}

function close() {
  localMessages.value=[]
  setTimeout(() => emits('onHide'), 400)
}


</script>

<style lang="scss" scoped>
.alert-message {position:fixed; top:10px; left:0; width:100%; background:rgba(0,0,0,0.8); z-index:10000;}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
